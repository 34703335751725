import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import ArticleList from "../components/articleList"

const CategoryPages = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />
      <ArticleList microCmsData={data.allMicrocmsBlog} />
      <hr />
      <Bio />
    </Layout>
  )
}

export default CategoryPages

export const query = graphql`
  query ($limit: Int!, $skip: Int!, $category: String!) {
    allMicrocmsBlog(
      limit: $limit
      skip: $skip
      sort: { fields: createdAt, order: DESC }
      filter: { category: { name: { eq: $category } } }
    ) {
      nodes {
        blogId
        title
        slug
        thumbnail {
          width
          height
          url
        }
        category {
          name
        }
        createdAt(formatString: "YYYY/MM/DD")
        updatedAt(formatString: "YYYY/MM/DD")
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`
